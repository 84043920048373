.TideHeight {
  font-family: "Space Mono", monospace; /* Use Space Mono font */
  color: white; /* Set text color to white */
  font-size: 6vw;
  font-weight: bold;
}

.RisingDropping {
  font-family: "Space Mono", monospace; /* Use Space Mono font */
  color: white; /* Set text color to white */
  font-size: 4vw;
}
