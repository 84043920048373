.SwellDirection {
  font-family: "Space Mono", monospace; /* Use Space Mono font */
  color: white; /* Set text color to white */
  font-size: 5vw;
  font-weight: bold;
}

.SwellHeightPeriod {
  font-family: "Space Mono", monospace; /* Use Space Mono font */
  color: white; /* Set text color to white */
  font-size: 3vw;
}

/* Smaller font size for screens larger than 600px */
@media screen and (min-width: 600px) {
  .SwellDirection {
    font-size: 4vw;
  }

  .SwellHeightPeriod {
    font-size: 2vw;
  }
}
